
@font-face {
font-family: "AnekMalayalam-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/AnekMalayalam/AnekMalayalam-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/AnekMalayalam/AnekMalayalam-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/AnekMalayalam/AnekMalayalam-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/AnekMalayalam/AnekMalayalam-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "AnjaliOldLipi-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/AnjaliOldLipi/AnjaliOldLipi-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/AnjaliOldLipi/AnjaliOldLipi-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/AnjaliOldLipi/AnjaliOldLipi-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/AnjaliOldLipi/AnjaliOldLipi-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "BalooChettan2-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/BalooChettan2/BalooChettan2-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/BalooChettan2/BalooChettan2-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/BalooChettan2/BalooChettan2-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/BalooChettan2/BalooChettan2-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "Chilanka-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/Chilanka/Chilanka-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Chilanka/Chilanka-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Chilanka/Chilanka-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Chilanka/Chilanka-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "Chingam-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/Chingam/Chingam-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Chingam/Chingam-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Chingam/Chingam-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Chingam/Chingam-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "Dyuthi-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/Dyuthi/Dyuthi-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Dyuthi/Dyuthi-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Dyuthi/Dyuthi-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Dyuthi/Dyuthi-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "Gayathri-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/Gayathri/Gayathri-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Gayathri/Gayathri-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Gayathri/Gayathri-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Gayathri/Gayathri-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "Karumbi-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/Karumbi/Karumbi-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Karumbi/Karumbi-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Karumbi/Karumbi-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Karumbi/Karumbi-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "Keraleeyam-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/Keraleeyam/Keraleeyam-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Keraleeyam/Keraleeyam-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Keraleeyam/Keraleeyam-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Keraleeyam/Keraleeyam-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "Manjari-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/Manjari/Manjari-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Manjari/Manjari-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Manjari/Manjari-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Manjari/Manjari-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "Meera-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/Meera/Meera-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Meera/Meera-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Meera/Meera-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Meera/Meera-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "NotoSansMalayalam-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/NotoSansMalayalam/NotoSansMalayalam-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/NotoSansMalayalam/NotoSansMalayalam-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/NotoSansMalayalam/NotoSansMalayalam-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/NotoSansMalayalam/NotoSansMalayalam-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "NotoSerifMalayalam-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/NotoSerifMalayalam/NotoSerifMalayalam-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/NotoSerifMalayalam/NotoSerifMalayalam-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/NotoSerifMalayalam/NotoSerifMalayalam-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/NotoSerifMalayalam/NotoSerifMalayalam-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "Ezhuthu-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/Ezhuthu/Ezhuthu-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Ezhuthu/Ezhuthu-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Ezhuthu/Ezhuthu-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Ezhuthu/Ezhuthu-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "Panmana-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/Panmana/Panmana-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Panmana/Panmana-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Panmana/Panmana-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Panmana/Panmana-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "Rachana-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/Rachana/Rachana-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Rachana/Rachana-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Rachana/Rachana-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Rachana/Rachana-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "Sundar-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/Sundar/Sundar-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Sundar/Sundar-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Sundar/Sundar-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Sundar/Sundar-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "Uroob-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/Uroob/Uroob-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Uroob/Uroob-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Uroob/Uroob-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Uroob/Uroob-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "TNJoy-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/TNJoy/TNJoy-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/TNJoy/TNJoy-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/TNJoy/TNJoy-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/TNJoy/TNJoy-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "RaghuMalayalamSans-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/RaghuMalayalamSans/RaghuMalayalamSans-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/RaghuMalayalamSans/RaghuMalayalamSans-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/RaghuMalayalamSans/RaghuMalayalamSans-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/RaghuMalayalamSans/RaghuMalayalamSans-Regular.otf) format("otf");
}   
  
@font-face {
font-family: "Suruma-Regular";
src: url(https://static.deshkeyboard.com/fonts/malayalam/Suruma/Suruma-Regular.woff2) format("woff2"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Suruma/Suruma-Regular.woff) format("woff"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Suruma/Suruma-Regular.ttf) format("truetype"),
  url(https://static.deshkeyboard.com/fonts/malayalam/Suruma/Suruma-Regular.otf) format("otf");
}   
  
