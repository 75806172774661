.toast {
  display: none;
  position: fixed;
  bottom: 16px;
  right: 16px;
  background-color: var(--clr-white);
  font-size: 0.875rem;
  border: 1px solid var(--opq-gray3-60);
  box-shadow: 1px 1px 10px 10px var(--opq-black-5);
  padding: 14px;
  border-radius: 8px;
  gap: 18px;
}

.closeButton {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
}

.closeButton svg {
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.link {
  font-weight: 600;
  color: var(--clr-black);
}

@media screen and (min-width: 1024px) {
  .toast {
    display: flex;
  }
}
