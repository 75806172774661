/* Styles for the whole header component */
.header {
  flex-direction: column;
}

/* Styles for the container inside the header which holds the text contents but not the line */
.container {
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 16px 0;
  width: 90%;
  margin: 0 auto;
  position: relative;
}

/* Styles for the logo div */
.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 16px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

/* Styles for the icon in the logo */
.icon {
  width: 32px;
  height: 32px;
  display: none;
}

/* Styles for the text in the logo */
.heading {
  color: var(--clr-black);
  font-size: 1.125rem;
  line-height: 1.7;
}

/* Styles for the go back link */
.link {
  color: var(--clr-black);
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  font-weight: 600;
}

/* Styles for the line under the header */
.line {
  background-color: var(--opq-black-10);
  width: 100%;
  height: 1px;
}

/* Responsive styles for tablet screen */
@media screen and (min-width: 768px) {
  .header {
    display: flex;
  }

  .container {
    width: 80%;
    padding: 24px 0;
  }

  .icon {
    display: block;
  }
}

/* Responsive styles for laptop screen */
@media screen and (min-width: 1024px) {
  .container {
    width: 70%;
  }

  .heading {
    font-size: 1.375rem;
  }
}
